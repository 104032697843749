<template>
  <div class="height1">
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body menu">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form  class="searchbox" :inline="true">
              <el-form-item label="">
                <el-input v-model="Keyword" type="text" size="small" placeholder="订单编号" @keyup.enter.native="search"  clearable ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields" worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出{{DataCount}}条数据</el-button>
                </download-excel>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(menu, index) in menuList" :key="index" :label="menu.label" :name="menu.value">
            <el-table class="tab" :data="tableData" stripe style="width: 100%" height="calc(100% - 40px)" border :header-cell-style="{ background: '#F5F7FC' }">
              <el-table-column prop="sonOrderNumber" label="订单编号" width="210px" fixed> </el-table-column>
              <el-table-column prop="freight" label="下单用户" align="center">
                <template slot-scope="data">
                  <p>{{ data.row.userName }}</p>
                  <!-- <p>{{ data.row.userId }}</p> -->
                </template>
              </el-table-column>
              <el-table-column label="商品名称" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">
                    {{ item.commodity.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="订单信息" width="130" align="center">
                <template slot-scope="scope">
                  <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">￥{{ item.actualMoney }}*{{ item.commodityNum }}</div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="actualMoney" label="订单总额" align="center"> </el-table-column> -->
              <el-table-column prop="freight" label="快递费用" align="center" width="100px">
                <!-- <template slot-scope="scope">
                  <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">
                    {{ item.freight }}
                  </div>
                </template> -->
              </el-table-column>
              <el-table-column prop="payMoney" label="实付金额" align="center"> </el-table-column>
              <!-- <el-table-column prop="totalShareDisMoney" label="分享佣金" align="center"> </el-table-column>
              <el-table-column prop="totalSharedMoney" label="分销金额" align="center"> </el-table-column>
              <el-table-column prop="totalPulicshare" label="分红金额" align="center"> </el-table-column> -->
              <el-table-column prop="platformCommissionMoney" label="平台抽佣" align="center"> </el-table-column>
              <el-table-column prop="agentCommissionMoney" label="代理商抽佣" align="center"> </el-table-column>
              <el-table-column prop="merchantActualMoney" label="实到金额（元）" align="center"> </el-table-column>
              <el-table-column prop="createTime" label="下单时间" align="center" width="180px">
                <template slot-scope="data">{{ data.row.createTime | timefilters }}</template>
              </el-table-column>
              <el-table-column label="订单状态" align="center" width="100px">
                <template slot-scope="scope">
                  <div class="orderprovos-box" v-for="(item, index) in scope.row.commodityOrder" :key="index">
                    <el-tag v-if="scope.row.state == 0" type="warning" size="small">待付款</el-tag>
                    <el-tag v-if="scope.row.state == 1" type="success" size="small">已完成</el-tag>
                    <el-tag v-if="scope.row.state == 2" size="small">待发货</el-tag>
                    <el-tag v-if="scope.row.state == 3" type="primary" size="small">已发货</el-tag>
                    <el-tag v-if="scope.row.state == -1" type="info" size="small">已取消</el-tag>
                    <el-tag v-if="scope.row.state == -2" type="danger" size="small">已删除</el-tag>
                    <el-tag v-if="scope.row.state == -999" type="danger" size="small">退款</el-tag>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { STATS } from '../../../../components/HospitalDomain/STATS'
import bread from '../../../../components/common/breadcrumb'

export default {
  name: 'OwnStats',
  components: {
    bread,
  },
  data() {
    var statistics = new STATS(this.TokenClient, this.Services.Shopping)
    return {
      StatisticsDomain: statistics,
      tableData: [],
      activeName: '%20',
      menuList: [
        {
          label: '全部',
          value: '%20',
        },
        {
          label: '普通商品',
          value: '1',
        },
        {
          label: '积分换购商品',
          value: '3',
        },
        {
          label: '处方药',
          value: '4',
        },
      ],
      Keyword: '',
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      title: '订单统计',
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: 'sonOrderNumber',
          callback: val => {
            return 'NO：' + val.sonOrderNumber
          },
        },
        下单用户: {
          callback: val => {
            return `${val.userName}【${val.userId}】`
          },
        },
        商品名称: {
          callback: val => {
            return this.formatName(val.commodityOrder)
          },
        },
        订单信息: {
          callback: val => {
            return this.formatOrderInfo(val.commodityOrder)
          },
        },
        订单总额: 'payMoney',
        快递费用: {
          callback: val => {
            return this.formatFreight(val.commodityOrder)
          },
        },
        实付金额: 'payMoney',
        // 分享佣金: 'totalShareDisMoney',
        // 分销金额: 'totalSharedMoney',
        // 分红金额: 'totalPulicshare',
        平台抽佣: 'platformCommissionMoney',
        实到金额: 'merchantActualMoney',
        下单时间: {
          filed: 'createTime',
          callback: val => {
            var val = val.createTime
            return this.$fliters.timefilters(val)
          },
        },
        订单状态: {
          callback: val => {
            return this.getOrderState(val.commodityOrder)
          },
        },
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleClick(tab, event) {
      this.PageIndex = 1
      this.getList()
    },
    getOrderState(val) {
      var res = ''
      val.forEach((item, i) => {
        switch (item.state) {
          case 0:
            res += `【待付款】`
            break
          case 1:
            res += `【已完成】`
            break
          case 2:
            res += `【待发货】`
            break
          case 3:
            res += `【已发货】`
            break
          case -1:
            res += `【已取消】`
            break
          case -2:
            res += `【已删除】`
            break
          case -999:
            res += `【退款】`
            break
        }
      })
      return res
    },
    formatOrderInfo(val) {
      var res = ''
      val.forEach((item, i) => {
        res += `【${item.payMoney}*${item.commodityNum}】`
      })
      return res
    },
    formatName(val) {
      var res = ''
      val.forEach((item, i) => {
        res += `【${item.commodity.name}】`
      })
      return res
    },
    formatFreight(val) {
      var res = ''
      val.forEach((item, i) => {
        res += `【${item.freight}】`
      })
      return res
    },
    OrderState(row) {
      if (row.state == '0') {
        return '待付款'
      } else if (row.state == '1') {
        return '已完成'
      } else if (row.state == '2') {
        return '待发货'
      } else if (row.state == '3') {
        return '已发货'
      } else if (row.state == '-1') {
        return '已取消'
      } else if (row.state == '-2') {
        return '已删除'
      } else if (row.state == '-999') {
        return '退款'
      }
    },
    search() {
      this.PageIndex = 1
      this.getList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this
      return new Promise((resolve, reject) => {
        this.StatisticsDomain.OrderStatisticsList(
          this.activeName,
          1,
          this.Keyword,
          _this.DataCount,
          function (data) {
            resolve(data.data.results)
          },
          function (err) {
            resolve('')
            console.log(err)
          }
        )
      })
    },
    getList() {
      this.StatisticsDomain.OrderStatisticsList(
        this.activeName,
        this.PageIndex,
        this.Keyword,
        null,
        data => {
          this.tableData = data.data.results
          this.PageIndex = data.data.pageIndex
          this.PageSize = data.data.pageSize
          this.PageTotal = data.data.pageCount
          this.DataTotal = data.data.dataTotal
            this.DataCount = data.data.dataTotal
        },
        err => {
          console.log(err)
        }
      )
    },
    DetailBtn(row) {
      this.$router.push({
        name: 'SalesDetail',
        query: {
          commodityId: row.id,
        },
      })
    },
  },
}
</script>

<style scoped>
/*content*/


.table-top {
  margin-bottom: -10px;
}

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  /* width: 480px; */
  position: relative;
}
::v-deep .el-table th.el-table__cell>.cell{
  padding:0 5px;
}
::v-deep .el-table .orderprovos-box{
  padding:10px 0;
}

.orderprovos-box {
  border-bottom: 1px solid #ebeef5;
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.orderprovos-box:last-child {
  border-bottom: none;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}

.table-img {
  position: relative;
  width: 200px;
  height: 120px;
  overflow: hidden;
}

.table-img img {
  width: 100%;
  height: auto;
}

.table-img p {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.table-info h4 {
  margin-bottom: 25px;
}

.table-info .el-row {
  margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
  width: 300px;
}
.menu /deep/ .el-tabs {
  height: calc(100% - 50px);
}

.menu /deep/ .el-tabs__content {
  height: calc(100% - 55px);
}

.menu /deep/ .el-tab-pane {
  height: 100%;
}
</style>
